"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletLinkUI = void 0;
class WalletLinkUI {
    constructor(_) { }
    /**
     * We want to disable showing the qr code for in-page walletlink if the dapp hasn't provided a json rpc url
     */
    setConnectDisabled(_) { }
}
exports.WalletLinkUI = WalletLinkUI;
